import React, { useState } from 'react';
import axios from 'axios';

const PaymentPage = () => {
  const [paymentDetails, setPaymentDetails] = useState({
    address: '',
    amount: '',
  });

  const handleChange = (e) => {
    setPaymentDetails({
      ...paymentDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handlePayment = async () => {
    try {
      const response = await axios.post('/api/payment', paymentDetails);
      // Обработка успешного платежа
      console.log('Payment successful:', response.data);
    } catch (error) {
      console.error('Payment failed:', error);
    }
  };

  return (
    <div>
      <h1>Payment</h1>
      <div>
        <label>USDT Address:</label>
        <input
          type="text"
          name="address"
          value={paymentDetails.address}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Amount:</label>
        <input
          type="number"
          name="amount"
          value={paymentDetails.amount}
          onChange={handleChange}
        />
      </div>
      <button onClick={handlePayment}>Pay</button>
    </div>
  );
};

export default PaymentPage;
