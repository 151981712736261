import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const CoursePage = () => {
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const fetchCourse = async () => {
      const response = await axios.get(`/api/courses/${courseId}`);
      setCourse(response.data);
    };

    const fetchProgress = async () => {
      const response = await axios.get(`/api/progress/${courseId}`);
      setProgress(response.data.progress);
    };

    fetchCourse();
    fetchProgress();
  }, [courseId]);

  const updateProgress = async (newProgress) => {
    setProgress(newProgress);
    await axios.post(`/api/progress/${courseId}`, { progress: newProgress });
  };

  if (!course) return <div>Loading...</div>;

  return (
    <div>
      <h1>{course.name}</h1>
      <div>
        <p>Progress: {progress}%</p>
        <button onClick={() => updateProgress(progress + 10)}>Update Progress</button>
      </div>
      <ul>
        {course.modules.map(module => (
          <li key={module._id}>{module.name}</li>
        ))}
      </ul>
    </div>
  );
};

export default CoursePage;
