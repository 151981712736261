import React from 'react';
import { NavLink } from 'react-router-dom';

const HomePage = () => {
  return (
    <div>
      {/* Основное меню навигации */}
      <nav style={{ padding: '10px', backgroundColor: '#f8f9fa', marginBottom: '20px' }}>
        <NavLink to="/" exact style={{ marginRight: '20px' }} activeStyle={{ fontWeight: 'bold', color: 'blue' }}>
          Home
        </NavLink>
        <NavLink to="/courses" style={{ marginRight: '20px' }} activeStyle={{ fontWeight: 'bold', color: 'blue' }}>
          Courses
        </NavLink>
        <NavLink to="/subscriptions" style={{ marginRight: '20px' }} activeStyle={{ fontWeight: 'bold', color: 'blue' }}>
          Subscriptions
        </NavLink>
        <NavLink to="/admin" style={{ marginRight: '20px' }} activeStyle={{ fontWeight: 'bold', color: 'blue' }}>
          Admin
        </NavLink>
        <NavLink to="/profile" style={{ marginRight: '20px' }} activeStyle={{ fontWeight: 'bold', color: 'blue' }}>
          Profile
        </NavLink>
      </nav>

      {/* Основной контент страницы */}
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <h1>Welcome to the Learning Platform</h1>
        <p>This is the homepage of your learning platform.</p>
        <p>You can navigate to different sections using the menu above.</p>
        
        <div style={{ marginTop: '20px' }}>
          <h2>Available Features</h2>
          <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
            <li style={{ marginBottom: '10px' }}>🎓 Explore Courses</li>
            <li style={{ marginBottom: '10px' }}>📚 View and Complete Modules</li>
            <li style={{ marginBottom: '10px' }}>📝 Take Tests to Check Your Knowledge</li>
            <li style={{ marginBottom: '10px' }}>💼 Track Your Learning Progress</li>
            <li style={{ marginBottom: '10px' }}>💳 Purchase Subscriptions</li>
            <li style={{ marginBottom: '10px' }}>📜 Earn Certificates</li>
          </ul>
        </div>
        
        <div style={{ marginTop: '40px' }}>
          <h2>Get Started</h2>
          <p>To get started, choose a course from the menu, or visit your profile to track your progress.</p>
          <p>Not registered yet? <a href="/register">Sign up now</a> to unlock all features.</p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
