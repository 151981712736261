import React, { useEffect, useState } from 'react';
import axios from 'axios';

const SubscriptionPage = () => {
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      const response = await axios.get('/api/subscriptions');
      setSubscriptions(response.data);
    };

    fetchSubscriptions();
  }, []);

  const handlePurchase = async (subscriptionId) => {
    try {
      const response = await axios.post(`/api/checkout`, { subscriptionId });
      // Обработка успешной покупки
      console.log('Purchase successful:', response.data);
    } catch (error) {
      console.error('Purchase failed:', error);
    }
  };

  return (
    <div>
      <h1>Available Subscriptions</h1>
      <ul>
        {subscriptions.map(subscription => (
          <li key={subscription._id}>
            {subscription.name} - ${subscription.price}
            <button onClick={() => handlePurchase(subscription._id)}>Purchase</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SubscriptionPage;
